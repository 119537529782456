import React, { Component, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { auth, functions, rtdb } from '../../services/firebase';
import './styles.css';

const Setup = () => {
  const history = useHistory();

  const [userId, setUserId] = useState('');
  const { urlConfigCode } = useParams<{ urlConfigCode: string }>();
  const [configCode, setConfigCode] = useState(
    urlConfigCode ? urlConfigCode : ''
  );
  const [streamName, setStreamName] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [portalExists, setPortalExists] = useState(false);
  const [myStreams, setMyStreams] = useState<string[]>([]);

  useEffect(() => {
    auth.onAuthStateChanged((userAuth) => {
      if (userAuth) {
        setUserId(userAuth.uid);
      } else {
        setUserId('');
      }
    });
  });

  useEffect(() => {
    if (urlConfigCode) {
      rtdb
        .ref('portals')
        .child(urlConfigCode)
        .get()
        .then(async (snapshot) => {
          if (snapshot.exists()) {
            setPortalExists(true);
            const data = await snapshot.val();
            console.log(data);
            if (!data.stream) {
              console.log("No stream set for portal (that's okay!)");
            } else {
              setStreamName(data.stream.name);
            }
          }
          setIsLoading(false);
        });
    } else {
      setPortalExists(false);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (userId) {
      rtdb.ref(`users/${userId}/streams`).on('value', (snapshot) => {
        const streams: string[] = snapshot.val();
        setMyStreams(Object.values(streams || {}));
      });
    }
  }, [userId]);

  async function save(e: React.MouseEvent<HTMLButtonElement>) {
    await functions.httpsCallable('updatePortal')({ configCode, streamName });
    history.push('/me');
  }

  async function addPortal(e: any) {
    await functions.httpsCallable('addPortal')({ portalId: configCode });
    history.push('/me');
  }

  function back(e: any) {
    history.push('/me');
  }

  function portalMessage() {
    if (urlConfigCode) {
      return <p>This Portal doesn't exist yet, would you like to add one?</p>;
    }
    return <p>To add a Portal, enter its Portal Code here!</p>;
  }

  if (isLoading) {
    return <p>Loading...</p>;
  }
  if (!portalExists) {
    return (
      <>
        <h1>Portal {configCode}</h1>
        {portalMessage()}
        <p>
          <input
            placeholder="XXXX"
            value={configCode}
            onChange={(e) => setConfigCode(e.target.value)}
          ></input>
        </p>
        <div className="button-container">
          <button onClick={back}>&#8592; BACK</button>
          <button onClick={addPortal} className="primary">
            ADD
          </button>
        </div>
      </>
    );
  }
  return (
    <>
      <h1>Configure your Portal ({configCode})</h1>

      <select
        name="streamName"
        placeholder="Steam Name"
        onChange={(e) => setStreamName(e.target.value)}
        value={streamName}
      >
        {myStreams.map((stream) => {
          return (
            <option key={stream} value={stream}>
              {stream}
            </option>
          );
        })}
      </select>

      <div className="button-container">
        <button onClick={back}>&#8592; BACK</button>
        <button onClick={save}>SAVE</button>
      </div>
    </>
  );
};

export default Setup;
