import React, { useState } from 'react';
import { TwitterPicker, ColorResult } from 'react-color';

const defaultColors = [
  '#ff6900', // orange
  '#fcb900', // gold
  '#7bdcb5', // light green
  '#00d084', // green
  '#8ed1fc', // sky blue
  '#0693e3', // blue
  '#eb144c', // red
  '#f78da7', // pink
  '#b697dd', // lilac
  '#9900ef', // purple
  '#000000', // black
  '#6b6873', // dark grey
  '#abb8c3', // grey
  '#f3f3f3', // white
];

const ColorPicker = ({
  onChange,
}: {
  onChange: (hexColor: string) => void;
}) => {
  const [currentColor, setCurrentColor] = useState('#123123');
  function handleChange(color: ColorResult) {
    setCurrentColor(color.hex);
    onChange(color.hex);
  }
  return (
    <TwitterPicker
      onChange={handleChange}
      colors={defaultColors}
      triangle="hide"
      color={currentColor}
    />
  );
};

export default ColorPicker;
