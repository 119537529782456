import React from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { auth } from '../../services/firebase';

export function Login() {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [signupInProgress, setSignupInProgress] = useState(false);
  const [message, setMessage] = useState('');

  function handleSignUp(e: any) {
    setSignupInProgress(true);
    auth
      .createUserWithEmailAndPassword(email, password)
      .then(() => {
        setMessage('Sign-up email sent! Check your email to continue!');
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log(errorCode, errorMessage);
        setMessage(errorMessage);
        setSignupInProgress(false);
      });
  }
  function handleLogIn(e: any) {
    setSignupInProgress(true);
    auth
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        history.push('/me');
        setMessage(
          `You're all logged in, ${JSON.stringify(
            auth.currentUser
          )}! Let's draw something!`
        );
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log(errorCode, errorMessage);
        setMessage(errorMessage);
        setSignupInProgress(false);
      });
  }
  return (
    <>
      <h1>Sign Up</h1>
      <input
        onChange={(e) => setEmail(e.target.value)}
        value={email}
        placeholder="Email"
        type="email"
        disabled={signupInProgress}
      ></input>
      <input
        onChange={(e) => setPassword(e.target.value)}
        value={password}
        type="password"
        disabled={signupInProgress}
      ></input>
      <button onClick={handleSignUp} disabled={signupInProgress}>
        SIGN UP
      </button>
      <button
        className="primary"
        onClick={handleLogIn}
        disabled={signupInProgress}
      >
        LOG IN
      </button>
      <p>{message}</p>
    </>
  );
}
