import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ReactSketchCanvas } from 'react-sketch-canvas';
import { storage, functions, rtdb } from '../../services/firebase';
import ColorPicker from './ColorPicker';
import './styles.css';

const Stream = () => {
  const history = useHistory();

  let { streamName } = useParams<{ streamName: string }>();
  var imageRef = storage.ref().child(`streams/${streamName}.png`);
  var pathsRef = storage.ref().child(`streams/${streamName}.json`);

  let myImage: ReactSketchCanvas | null;
  const [penColor, setPenColor] = useState('red');
  const [previousDrawing, setPreviousDrawing] = useState([] as any[]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingImage, setIsLoadingImage] = useState(true);
  const [streamExists, setStreamExists] = useState(true);

  useEffect(() => {
    rtdb
      .ref('streams')
      .child(streamName)
      .on('value', (snapshot) => {
        const stream = snapshot.val();
        if (stream) {
          console.log('Found Stream!', snapshot.val());
        } else {
          console.log("Stream doesn't exist");
          setStreamExists(false);
        }
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (previousDrawing.length > 0) {
      myImage?.loadPaths(previousDrawing);
    }
  }, [previousDrawing]);

  useEffect(() => {
    console.log('Loaded in!');
    pathsRef.getDownloadURL().then(
      async (url) => {
        const paths = await fetch(url);
        setPreviousDrawing(await paths.json());
        setIsLoadingImage(false);
      },
      (error) => {
        if (error.code === 'storage/object-not-found') {
          console.log('Previous Stream data not found');
          setIsLoadingImage(false);
        } else {
          console.log(JSON.stringify(error));
        }
      }
    );
  }, []);

  async function submit(e: any) {
    try {
      await imageRef.putString(await myImage!.exportImage('png'), 'data_url');
      await pathsRef.putString(JSON.stringify(await myImage!.exportPaths()));
    } catch (e) {
      console.log(e);
    }
  }
  function clear(e: any) {
    myImage?.clearCanvas();
  }
  function back(e: any) {
    history.push('/me');
  }

  async function create(e: any) {
    await functions.httpsCallable('createStream')('');
    history.push('/me');
  }
  if (isLoading) {
    return <>Loading...</>;
  }
  if (!streamExists) {
    return (
      <>
        <h1>Stream {streamName}</h1>
        <p>This stream doesn't exist yet, would you like to create one?</p>
        <div className="button-container">
          <button onClick={create}>CREATE</button>
        </div>
      </>
    );
  }
  return (
    <div className="stream-frame">
      <h1>Stream {streamName}</h1>
      <ReactSketchCanvas
        className="paint-canvas"
        width="240"
        height="240"
        strokeWidth={4}
        strokeColor={penColor}
        canvasColor={isLoadingImage ? 'lightgrey' : 'black'}
        ref={(ref) => (myImage = ref)}
      />

      <ColorPicker onChange={setPenColor}></ColorPicker>
      <div className="button-container">
        <button onClick={back}>&#8592; BACK</button>
        <button onClick={clear}>CLEAR</button>
        <button className="primary" onClick={submit}>
          SUBMIT
        </button>
      </div>
    </div>
  );
};

export default Stream;
