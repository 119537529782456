import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/database';
import 'firebase/functions';
import 'firebase/auth';
import { firebaseConfig } from './local-config';

firebase.initializeApp(firebaseConfig);
export const storage = firebase.storage();
export const rtdb = firebase.database();
export const functions = firebase.functions();
export const auth = firebase.auth();
