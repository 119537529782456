// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: 'AIzaSyDkfEiM0bEPg6VjKBmidvQTX-SKDgGAPQg',
  authDomain: 'crump-portal.firebaseapp.com',
  projectId: 'crump-portal',
  storageBucket: 'crump-portal.appspot.com',
  messagingSenderId: '3023178929',
  appId: '1:3023178929:web:7989958263853f44ce2690',
  measurementId: 'G-91NV7PCZK0',
};
