import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import Setup from '../Setup';
import Home from '../Home';

import Stream from '../Stream';
import Me from '../Me';
import { Login } from '../Login';

const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Me />
        </Route>
        <Route path="/login">
          <Login></Login>
        </Route>
        <Route exact path="/me">
          <Me />
        </Route>
        <Route path="/stream/:streamName">
          <Stream></Stream>
        </Route>
        <Route path="/setup/:urlConfigCode">
          <Setup></Setup>
        </Route>
        <Route path="/setup">
          <Setup></Setup>
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
