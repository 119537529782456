import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { auth, functions, rtdb } from '../../services/firebase';
import './styles.css';
const Me = () => {
  const history = useHistory();
  const [userId, setUserId] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [myPortals, setMyPortals] = useState<string[]>([]);
  const [myStreams, setMyStreams] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    auth.onAuthStateChanged((userAuth) => {
      if (userAuth) {
        setUserId(userAuth.uid);
      } else {
        history.push('/login');
        setUserId('');
      }
    });
  });
  useEffect(() => {
    if (userId) {
      rtdb
        .ref('users')
        .child(userId)
        .on('value', (snapshot) => {
          const userInfo: {
            portals: string[];
            streams: string[];
            displayName: string;
          } = snapshot.val();
          setMyPortals(Object.values(userInfo?.portals || []));
          setMyStreams(Object.values(userInfo?.streams || []));
          setDisplayName(userInfo.displayName);
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, [userId]);

  const portalList = myPortals ? (
    <ul>
      {myPortals.map((portal) => (
        <li key={portal}>
          <Link to={`/setup/${portal}`}>{portal}</Link>
        </li>
      ))}
    </ul>
  ) : (
    <>No portals</>
  );

  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <h1>Hello, {displayName}</h1>
      <h4>
        Not {displayName}?{' '}
        <a
          href="true"
          onClick={(e) => {
            e.preventDefault();
            auth.signOut();
          }}
        >
          Log out
        </a>
      </h4>
      <h4>
        Portals <a href="/setup">Add Portal</a>
      </h4>
      {portalList}
      <h4>
        Streams{' '}
        <a
          href="true"
          onClick={(e) => {
            e.preventDefault();
            functions.httpsCallable('createStream')('');
          }}
        >
          New Stream
        </a>
      </h4>
      <ul>
        {myStreams.map((stream) => (
          <li key={stream}>
            <Link to={`/stream/${stream}`}>{stream}</Link>
          </li>
        ))}
      </ul>
    </>
  );
};
export default Me;
